import { render, staticRenderFns } from "./SInputText.vue?vue&type=template&id=ab917e20&scoped=true"
import script from "./sInputText.ts?vue&type=script&lang=ts&external"
export * from "./sInputText.ts?vue&type=script&lang=ts&external"
import style0 from "./SInputText.vue?vue&type=style&index=0&id=ab917e20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab917e20",
  null
  
)

export default component.exports