import { render, staticRenderFns } from "./SwitchEnglish.vue?vue&type=template&id=1b8e4825&scoped=true"
import script from "./switchEnglish.ts?vue&type=script&lang=ts&external"
export * from "./switchEnglish.ts?vue&type=script&lang=ts&external"
import style0 from "./SwitchEnglish.vue?vue&type=style&index=0&id=1b8e4825&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8e4825",
  null
  
)

export default component.exports