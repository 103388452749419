import { render, staticRenderFns } from "./SInputTextarea.vue?vue&type=template&id=274662f5&scoped=true"
import script from "./sInputTextarea.ts?vue&type=script&lang=ts&external"
export * from "./sInputTextarea.ts?vue&type=script&lang=ts&external"
import style0 from "./SInputTextarea.vue?vue&type=style&index=0&id=274662f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274662f5",
  null
  
)

export default component.exports