import { render, staticRenderFns } from "./MoreFilters.vue?vue&type=template&id=50e50e68&scoped=true"
import script from "./moreFilters.ts?vue&type=script&lang=ts&external"
export * from "./moreFilters.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e50e68",
  null
  
)

export default component.exports