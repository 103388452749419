import { render, staticRenderFns } from "./SButtonBack.vue?vue&type=template&id=f735e0da&scoped=true"
import script from "./sButtonBack.ts?vue&type=script&lang=ts&external"
export * from "./sButtonBack.ts?vue&type=script&lang=ts&external"
import style0 from "./SButtonBack.vue?vue&type=style&index=0&id=f735e0da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f735e0da",
  null
  
)

export default component.exports