import { render, staticRenderFns } from "./SAutocomplete.vue?vue&type=template&id=0a3dc463&scoped=true"
import script from "./sAutocomplete.ts?vue&type=script&lang=ts&external"
export * from "./sAutocomplete.ts?vue&type=script&lang=ts&external"
import style0 from "./SAutocomplete.vue?vue&type=style&index=0&id=0a3dc463&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3dc463",
  null
  
)

export default component.exports